<template>
  <div>
    <el-timeline>
      <el-timeline-item
        v-for="item in billInfo.change"
        :key="item.id"
        :timestamp="item.changeDate | datetime('YYYY年MM月DD日 HH:mm:ss')"
        placement="top"
      >
        <el-card shadow="hover">
          <h4>{{ item.remark }}</h4>
          <p>
            <el-tag type="warning">{{ item.changeItem }}</el-tag
            >，由 <el-tag type="success">{{ item.oldValue || "空" }}</el-tag
            >更改为
            <el-tag type="info">{{ item.newValue || "空" }}</el-tag>
          </p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
    <el-button-group>
      <el-button type="primary" @click="$router.push({ name: 'ContractList' })"
        >列表</el-button
      >
      <el-button type="warning" @click="close">关闭</el-button>
      <el-button type="danger" @click="deleteContract">删除</el-button>
    </el-button-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routeTab: "合同",
      billInfo: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$get("Contract/ContractInfo", {
        id: this.$route.params.id,
      }).then((r) => {
        this.billInfo = r;
        if (this.billInfo.contractName) {
          this.routeTab = this.billInfo.contractName;
        }
      });
    },
    deleteContract() {
      this.$confirm(
        "将删除【" + this.billInfo.contractName + "】, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.deleteById();
        })
        .catch(() => {});
    },
    deleteById() {
      this.$post("Contract/DeleteContract?id=" + this.billInfo.id).then((r) => {
        this.$g.deleteAttach(this.billInfo.attachId);
        this.$message.success("操作成功！");
        this.$tabs.close();
      });
    },
    close() {
      this.$tabs.close();
    },
  },
};
</script>

<style>
</style>